<template>
<form
  id="app"
  v-on:submit.prevent="SalvarAMO()"
  method="post"
>
  <b-card-code title="Formulario A.M.O.">
    <b-row v-if="solidesenvolvimentoid > 0">
      <b-col
        md="6"
        xl="2"
        class="mb-1"
      >
        <b-form-group
          label="Nº Solicitação:"
        >
          <b-form-input
            id="numsoliproduto"
            placeholder=""
            v-model="numsoliproduto"
            readonly
          />
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        xl="10"
        class="mb-1"
      >
        <b-form-group
          label="Produto:"
        >
          <b-form-input
            id="produtoInput"
            placeholder=""
            v-model="produto"
            required
            readonly
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        md="6"
        xl="6"
        class="mb-1"
      >
        <b-form-group
          label="Já envio informações para o órgão competente?"
        >
        <div>
          <input name="envioorgcompetente" type="radio" id="Sim" value="Sim" v-model="envioorgcompetente" required :disabled="PermiteSalvar ? false : '' "> Sim
          <input name="envioorgcompetente" type="radio" id="Nao" value="Não" v-model="envioorgcompetente" required :disabled="PermiteSalvar ? false : '' "> Não
        </div>
        </b-form-group>
      </b-col>
    </b-row>

  <b-row>
      <b-col
        md="6"
        xl="3"
        class="mb-1"
      >
        <b-form-group
          label="Data Produto aprovado no órgão competente:"
        >
          <b-form-datepicker
            id="dataprodaprovorgcompetente" locale="pr-BR"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            today-button
            reset-button
            v-model="dataprodaprovorgcompetente"
            placeholder="Selecione"
            :disabled="PermiteSalvar ? false : '' "
          />
        </b-form-group>
      </b-col>
  </b-row>

    <b-row>
      <b-col
        md="6"
        xl="3"
        class="mb-1"
      >
        <b-form-group
          label="REG SIF N°:"
        >
          <b-form-input
            id="regsif"
            placeholder=""
            v-model="regsif"
            rows="1"
            :readonly="PermiteSalvar ? false : '' "
          />
        </b-form-group>
      </b-col>
    </b-row>

   <b-row>
         <b-col
        md="6"
        xl="12"
        class="mb-1"
      >
      <div class="DivBotaoLadoLado" >
       <b-button
      variant="danger"
      type="button"
      @click="$router.push('../DevProduto')"
    >
      <span>RETORNAR</span>
    </b-button>
      </div>
      <div class="DivBotaoLadoLado" >
    <b-button
      variant="success"
      type="submit"
      v-if="PermiteSalvar"
    >
      <span>SALVAR</span>
    </b-button>
      </div>
    </b-col>

    </b-row>
  </b-card-code>
</form>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  BFormInput, BRow, BCol, BFormGroup, BButton, BFormDatepicker,
} from 'bootstrap-vue'
import apiPortalSky from '@/services/apiPortalSky'
import AlertaMsg from '@/customPortal/AlertaMsg.vue'
import ConexaoAPI from '@/customPortal/ConexaoAPI.vue'

export default {
  components: {
    BCardCode,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BButton,
    BFormDatepicker,
  },
  data() {
    return {
      Desenvolvimento: {
      },
      solidesenvolvimentoid: 0,
      numsoliproduto: '',
      produto: '',
      envioorgcompetente: '',
      dataprodaprovorgcompetente: '',
      regsif: '',
      tipoalteracao: '',
      acessoetapa: 0,
    }
  },
  methods: {
    async SalvarAMO() {
      let notification = ''
      let MsgErro = ''
      this.tipoalteracao = 'AMO'
      const usuario = this.$session.get('usuario')
      const URLpost = `/SoliDesenvolvimento/SoliDesenvolvimentoEtapas?CODUSU=${usuario.codusu}`
      this.CarregaDadosNoObjeto() // Carrega os Dados no Objeto para enviar no Parametro
      const ParametroJson = JSON.parse(JSON.stringify(this.Desenvolvimento))
      await apiPortalSky.post(URLpost, ParametroJson, {
        headers: {
          'Content-Type': 'application/json',
        },
      }).then(response => {
        if (response.status === 200) {
          // this.Desenvolvimento = response.data
          MsgErro = `Formulario ${this.numsoliproduto} Alterado com Sucesso`
          notification = { message: MsgErro, alert_type: 'success' }
          this.$session.set('notification', notification)
          // Faz a navegação para a Tela de Lista
          this.$router.push('../DevProduto')
        }
      })
        .catch(error => {
          notification = ConexaoAPI.ValidaErro(error)
          AlertaMsg.FormataMsg(notification)
        })
    },
    async MostraFormularioSolicitacao($SOLIDESENVOLVIMENTOID) {
      let notification = ''
      const usuario = this.$session.get('usuario')
      const SOLIDESENVOLVIMENTOID = $SOLIDESENVOLVIMENTOID
      const ETAPA = 'AMO'
      const URLpost = `/SoliDesenvolvimento/SoliDesenvolvimentoMostra?CODUSU=${usuario.codusu}&SOLIDESENVOLVIMENTOID=${SOLIDESENVOLVIMENTOID}&ETAPA=${ETAPA}`
      await apiPortalSky.post(URLpost, SOLIDESENVOLVIMENTOID).then(response => {
        if (response.status === 200) {
          this.Desenvolvimento = response.data
          this.DescarregaDadosDoObjeto()
          this.ValidaAcesso()
        }
      }).catch(error => {
        notification = ConexaoAPI.ValidaErro(error)
        AlertaMsg.FormataMsg(notification)
      })
    },
    CarregaDadosNoObjeto() {
      this.Desenvolvimento.solidesenvolvimentoid = this.solidesenvolvimentoid
      this.Desenvolvimento.numsoliproduto = this.numsoliproduto
      this.Desenvolvimento.envioorgcompetente = this.envioorgcompetente
      this.Desenvolvimento.dataprodaprovorgcompetente = this.dataprodaprovorgcompetente
      this.Desenvolvimento.regsif = this.regsif
      this.Desenvolvimento.tipoalteracao = this.tipoalteracao
    },
    DescarregaDadosDoObjeto() {
      this.solidesenvolvimentoid = this.Desenvolvimento.solidesenvolvimentoid
      this.numsoliproduto = this.Desenvolvimento.numsoliproduto
      this.produto = this.Desenvolvimento.produto
      this.envioorgcompetente = this.Desenvolvimento.envioorgcompetente
      this.dataprodaprovorgcompetente = this.Desenvolvimento.dataprodaprovorgcompetente
      this.regsif = this.Desenvolvimento.regsif
      this.tipoalteracao = this.Desenvolvimento.tipoalteracao
      this.acessoetapa = this.Desenvolvimento.acessoetapa
    },
    ValidaAcesso() {
      if (this.acessoetapa === 1) {
        this.PermiteSalvar = true
      }
    },
  },
  mounted() {
    if (this.$route.params.solidesenvolvimentoid != null) {
      this.MostraFormularioSolicitacao(this.$route.params.solidesenvolvimentoid)
    }
  },
}
</script>
